import { v4 as uuid } from "uuid";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { TextStyleType, BlockStructureType, NodeType, AuthoringBlockType } from "common/constants";
import { Shape } from "js/core/utilities/shapes";
import { detectListContent } from "js/core/services/sharedModelManager";

import { BaseElement } from "../base/BaseElement";
import { TextElement } from "../base/Text/TextElement";
import { SVGPolylineElement } from "../base/SVGElement";
import { AnnotationLayer } from "./AnnotationLayer";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TimelineSpanChevronItem } from "./timelineSpans/TimelineSpanChevronItem";
import { TimelineSpanSliceItem } from "./timelineSpans/TimelineSpanSliceItem";

class TimelineAnnotations extends AnnotationLayer {
    get maxItemCount() {
        return 11;
    }

    get canEditNumberedTextContentItemMarkerValue() {
        return false;
    }

    getAllowedNodeTypes() {
        return [];
    }

    getChildOptions(model) {
        const sortedItems = _.cloneDeep(this.model.items).sort((a, b) => a.x - b.x).map(({ x, y, id }, index) => ({ x, y, id, index }));

        return {
            ...super.getChildOptions(model),
            canChangeTextDirection: false,
            allowTextAlignment: false,
            horizontalScaleOrigin: "left",
            textAlign: "left",
            itemIndex: sortedItems.find(({ id }) => id === model.id).index,
            allowedTextBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            allowedFrameCategories: ["shape"],
            showResizeSlider: false,
            syncFontSizeWithSiblings: true,
            getRegistrationPoint: model.nodeType === NodeType.TEXT
                ? element => new geom.Point(-element.text.calculatedProps.textBounds.left - element.anchorPadding, 0)
                : null,
            getAnchorPoint: model.nodeType === NodeType.TEXT
                ? (element, connector, anchor) => {
                    const anchorBounds = element.text.calculatedProps.textBounds.offset(element.bounds.position);
                    return new geom.Point(anchorBounds.left - element.anchorPadding, model.y > 0.5 ? anchorBounds.bottom : anchorBounds.top);
                }
                : null
        };
    }

    getConnectorGroupOptions() {
        return {
            ...super.getConnectorGroupOptions(),
            getConnectorOptions: () => ({
                canSelect: false,
                convertToAuthoringAsSVG: true
            })
        };
    }

    get constrainNodesToBounds() {
        return false;
    }

    getAnimations() {
        const sortedItems = this.itemElements
            .map(({ id, itemIndex }) => ({ id, itemIndex }))
            .sort((a, b) => a.itemIndex - b.itemIndex)
            .map(({ id }) => this.elements[id]);

        return sortedItems.map(node => {
            const nodeAnimations = node.getAnimations();

            node.connectorsFromNode.forEach(connector => {
                const connectorAnimations = connector.getAnimations();
                nodeAnimations.push(...connectorAnimations);
            });

            // Combine node and connectors animations into one
            const animation = {
                ...nodeAnimations[0]
            };

            animation.animatingElements = nodeAnimations.map(({ element }) => element);
            animation.prepare = () => nodeAnimations
                .filter(({ prepare }) => !!prepare)
                .forEach(({ prepare }) => prepare());
            animation.onBeforeAnimationFrame = progress => {
                nodeAnimations
                    .filter(({ onBeforeAnimationFrame }) => !!onBeforeAnimationFrame)
                    .forEach(({ onBeforeAnimationFrame }) => onBeforeAnimationFrame(progress));
                // Letting the canvas know it should refresh annotations layer on every frame
                return this;
            };
            animation.finalize = () => nodeAnimations
                .filter(({ finalize }) => !!finalize)
                .forEach(({ finalize }) => finalize());

            return animation;
        });
    }
}

export class Timeline extends BaseElement {
    static get schema() {
        return {
            spans: [],
            annotations: {
                items: []
            }
        };
    }

    get canRefreshElement() {
        return true;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get spanStyle() {
        if (this.spanCount === 0) return "line";
        return this.model.spanStyle ?? "line";
    }

    get spans() {
        return this.model.spans;
    }

    get isReversed() {
        return this.spanCount > 0 && !!this.model.reversed;
    }

    get canRollover() {
        return true;
    }

    get maxSpanCount() {
        return 10;
    }

    get spanCount() {
        return this.model.spans?.length ?? 0;
    }

    get sortedMilestoneIds() {
        return _.cloneDeep(this.model.annotations.items).sort((a, b) => a.x - b.x).map(({ id }) => id);
    }

    get hasDraggedMilestones() {
        return this.model.annotations.items.some(({ hasBeenDragged }) => hasBeenDragged);
    }

    getCanvasMargins() {
        return {
            left: this.model.showStartMarker ? 50 : 0,
            right: this.model.showEndMarker ? 50 : 0,
            top: 50,
            bottom: 50
        };
    }

    getDefaultAnnotationModel() {
        return {
            id: uuid(),
            nodeType: this.model.annotations?.items[0]?.nodeType ?? NodeType.TEXT,
            hasBeenDragged: false,
            isNew: true,
            x: Math.random(),
            y: this.model.annotations ? (this.model.annotations.items.length % 2 === 0 ? 0.25 : 0.75) : 0.25,
            hideNodeConnectorWidget: true,
            textDirection: "right"
        };
    }

    getAnnotationConnectorModel(annotationId, startAnchor, targetPath) {
        return {
            id: `from-${annotationId}`,
            source: annotationId,
            target: `${this.uniquePath}/${targetPath}`,
            endPointIsLocked: true,
            canChangeConnectorType: false,
            endDecoration: this.drawConnectorDecoration,
            startAnchor
        };
    }

    drawConnectorDecoration(pt) {
        let r;
        switch (this.canvas.getTheme().get("styleWeight")) {
            case "light":
                r = 5;
                break;
            case "medium":
                r = 8;
                break;
            case "heavy":
                r = 10;
                break;
        }

        return {
            trimAmount: 0,
            path: Shape.drawCircle(r, pt).toPathData()
        };
    }

    _build() {
        if (this.model.showStartMarker) {
            this.startMarker = this.addElement("start_marker", () => TimelineMarker, {
                model: this.model.startMarker,
                scaleTextToFit: true,
                canDelete: true,
                placeholder: "Type text",
                blockStructure: BlockStructureType.SINGLE_BLOCK,
                defaultBlockTextStyle: TextStyleType.BODY,
                matchTextScaleId: "marker",
                syncFontSizeWithSiblings: !!this.model.showEndMarker,
                getSiblings: () => [this.startMarker, this.endMarker],
                showTextDecoration: this.spanStyle === "line",
                deleteCallback: async () => {
                    this.model.showStartMarker = false;
                    await this.canvas.updateCanvasModel(true);
                }
            });
        }
        if (this.model.showEndMarker) {
            this.endMarker = this.addElement("end_marker", () => TimelineMarker, {
                model: this.model.endMarker,
                scaleTextToFit: true,
                canDelete: true,
                placeholder: "Type text",
                blockStructure: BlockStructureType.SINGLE_BLOCK,
                defaultBlockTextStyle: TextStyleType.BODY,
                matchTextScaleId: "marker",
                syncFontSizeWithSiblings: !!this.model.showStartMarker,
                getSiblings: () => [this.startMarker, this.endMarker],
                showTextDecoration: this.spanStyle === "line",
                deleteCallback: async () => {
                    this.model.showEndMarker = false;
                    await this.canvas.updateCanvasModel(true);
                }
            });
        }

        // if length === 0, then we need to show the time line span
        if (this.spanStyle !== "line") {
            this.timeline = this.addElement("spans", () => TimelineSpans, {
                spanStyle: this.spanStyle,
                maxSpanCount: this.maxSpanCount,
                spanCount: this.spanCount,
                showStartMarker: this.model.showStartMarker,
                showEndMarker: this.model.showEndMarker
            });
        } else {
            this.model.spans = [];
            this.timeline = this.addElement("timeline", () => TimelineLine);
        }

        if (!this.model.annotations.items) {
            this.model.annotations = { items: [] };
        }
        if (!this.model.annotations.connections) {
            this.model.annotations.connections = { items: [] };
        }

        // Generate connectors
        this.model.annotations.connections.items = this.model.annotations.items
            .map(milestoneModel =>
                this.getAnnotationConnectorModel(milestoneModel.id,
                    milestoneModel.nodeType === NodeType.TEXT ? geom.AnchorType.LEFT : geom.AnchorType.FREE,
                    this.model.spans.length ? "spans" : "timeline")
            );

        this.annotations = this.addElement("annotations", () => TimelineAnnotations, { model: this.model.annotations });
        this.annotations.layer = 2;
    }

    _calcProps(props) {
        const { size } = props;

        // Timeline bounds start at full bounds and then are reduced if showing start/end markers
        let timelineBounds = new geom.Rect(0, 0, size);

        if (this.model.showStartMarker) {
            const startMarker = this.startMarker.calcProps(new geom.Size(this.styles.markerSize, this.styles.markerSize));
            startMarker.bounds = new geom.Rect(0, size.height / 2 - this.styles.markerSize / 2, this.styles.markerSize, this.styles.markerSize);
            timelineBounds = timelineBounds.deflate({ left: this.styles.markerSize });
        }
        if (this.model.showEndMarker) {
            const endMarker = this.endMarker.calcProps(new geom.Size(this.styles.markerSize, this.styles.markerSize));
            endMarker.bounds = new geom.Rect(size.width - this.styles.markerSize, size.height / 2 - this.styles.markerSize / 2, this.styles.markerSize, this.styles.markerSize);
            timelineBounds = timelineBounds.deflate({ right: this.styles.markerSize });
        }

        if (this.timeline instanceof TimelineLine) {
            // Add props for the timeline line
            this.timeline.createProps({
                path: [[timelineBounds.left, timelineBounds.height / 2], [timelineBounds.right, timelineBounds.height / 2 + 1]],
                layer: -1,
                bounds: timelineBounds
            });
        } else {
            const spanBounds = timelineBounds.clone();
            const spanProps = this.timeline.calcProps(spanBounds.size);
            spanProps.bounds = spanBounds;
        }

        const hasDraggedMilestones = this.hasDraggedMilestones;
        if (!hasDraggedMilestones) {
            this.resetMilestonePositions();
        }

        const annotationsBounds = timelineBounds.clone();

        const calcAnnotations = (isRecalc = false) => {
            const annotationProps = this.annotations.calcProps(annotationsBounds.size);
            annotationProps.bounds = annotationsBounds;

            if (isRecalc) return;

            const centerV = annotationsBounds.height / 2;
            this.annotations.itemElements.forEach(annotationElement => {
                const elementPositionY = annotationElement.model.y * annotationsBounds.size.height;

                let topGap = 0.45;
                let bottomGap = 0.55;
                let needsAdjustment = annotationElement.bounds.top < centerV && annotationElement.bounds.bottom > centerV;

                if (this.elements?.spans?.itemCount > 0) {
                    const spanHeightMargin = Math.max(...this.elements.spans.itemElements.map(element => element.bounds.height)) / 2;
                    topGap = 0.4;
                    bottomGap = 0.6;
                    needsAdjustment =
                        // Top of annotation is in the middle of the span
                        annotationElement.bounds.top > centerV - spanHeightMargin && annotationElement.bounds.top < centerV + spanHeightMargin ||
                        // Bottom of annotation is in the middle of the span
                        annotationElement.bounds.bottom > centerV - spanHeightMargin && annotationElement.bounds.bottom < centerV + spanHeightMargin ||
                        // Top is above the span and bottom is below the span
                        annotationElement.bounds.top < centerV - spanHeightMargin && annotationElement.bounds.bottom > centerV + spanHeightMargin;
                }

                if (needsAdjustment) {
                    const moveToTop = (centerV - annotationElement.bounds.top) > (annotationElement.bounds.bottom - centerV);
                    if (moveToTop) {
                        const elementOffset = Math.max(0, annotationElement.bounds.bottom - elementPositionY) / annotationsBounds.height;
                        annotationElement.model.y = Math.max(0, topGap - elementOffset);
                    } else {
                        const elementOffset = Math.max(0, elementPositionY - annotationElement.bounds.top) / annotationsBounds.height;
                        annotationElement.model.y = Math.min(1 - annotationElement.bounds.height / annotationsBounds.height, bottomGap + elementOffset);
                    }

                    calcAnnotations(true);
                } else if (annotationElement.bounds.top < 0) {
                    annotationElement.model.y = 0;
                } else if ((annotationElement.bounds.bottom > annotationsBounds.height)) {
                    annotationElement.model.y = Math.max(0, 1 - annotationElement.bounds.height / annotationsBounds.height);
                }
            });
        };

        calcAnnotations();

        if (!hasDraggedMilestones) {
            this.distributeMilestonePositions();
            calcAnnotations();
        }

        return { size };
    }

    resetMilestonePositions() {
        const sortedElements = this.sortedMilestoneIds.map(id => this.model.annotations.items.find(item => item.id === id));

        // First, distribute horizontally and remove hasBeenDragged
        sortedElements.forEach((milestoneModel, idx) => {
            milestoneModel.x = ((1 / (this.sortedMilestoneIds.length + 1)) * (idx + 1)) - ((idx + 1) * 0.01);
            milestoneModel.y = this.model.reversed
                ? idx % 2 === 0 ? 0.75 : 0.25
                : idx % 2 === 0 ? 0.25 : 0.75;
            milestoneModel.hasBeenDragged = false;

            milestoneModel.isNew = false;
        });
    }

    distributeMilestonePositions() {
        // Sorted milestone elements
        const sortedMilestones = this.sortedMilestoneIds.map(id => this.annotations.elements[id]);

        // Distribute vertically in case there are intersections
        const annotationsBounds = this.annotations.calculatedProps.bounds.zeroOffset();
        const getBounds = milestone => milestone.anchorBounds.union(milestone.text.calculatedProps.bounds.offset(milestone.calculatedProps.bounds.position));
        const getMaxHeight = milestones => milestones.reduce((maxHeight, milestone) => Math.max(getBounds(milestone).height, maxHeight), 0);
        const distributeRow = (milestones, availableBounds, shiftToTop) => {
            const hasIntersections = milestones.reduce(
                (hasIntersections, milestone) =>
                    hasIntersections ||
                    milestones.filter(m => m !== milestone).some(m => getBounds(m).intersects(getBounds(milestone), true)),
                false
            );
            if (!hasIntersections) {
                milestones.forEach(milestone => {
                    if (shiftToTop) {
                        if (getBounds(milestone).bottom > availableBounds.bottom) {
                            milestone.model.y = availableBounds.top / annotationsBounds.height;
                        }
                    }
                });

                return;
            }

            // Split milestones in two rows
            const topMilestones = milestones.filter((_, idx) => idx % 2 !== 0);
            const bottomMilestones = milestones.filter((_, idx) => idx % 2 === 0);

            const topMilestonesHeight = getMaxHeight(topMilestones);
            const bottomMilestonesHeight = getMaxHeight(bottomMilestones);

            const verticalPadding = Math.max(0, (availableBounds.height - topMilestonesHeight - bottomMilestonesHeight) / 3);

            const topBounds = availableBounds.removePadding({ top: verticalPadding, left: 0, bottom: verticalPadding * 2 + bottomMilestonesHeight, right: 0 });
            topMilestones.forEach(milestone => {
                milestone.model.y = topBounds.top / annotationsBounds.height;
            });

            const bottomBounds = availableBounds.removePadding({ top: verticalPadding * 2 + topMilestonesHeight, left: 0, bottom: verticalPadding, right: 0 });
            bottomMilestones.forEach(milestone => {
                milestone.model.y = bottomBounds.top / annotationsBounds.height;
            });
        };

        const bottomMilestones = sortedMilestones.filter(milestone => milestone.model.y >= 0.5);
        distributeRow(bottomMilestones, annotationsBounds.removePadding({ top: this.annotations.bounds.height / 2, left: 0, bottom: 0, right: 0 }));

        const topMilestones = sortedMilestones.filter(milestone => milestone.model.y < 0.5);
        distributeRow(topMilestones, annotationsBounds.removePadding({ top: 0, left: 0, bottom: this.annotations.bounds.height / 2, right: 0 }, true));
    }

    async distributeMilestones() {
        this.resetMilestonePositions();
        await this.canvas.dryRefreshCanvas();
        this.distributeMilestonePositions();
        this.distributeSpans();
    }

    distributeSpans() {
        this.spans.forEach(span => {
            delete span.width;
        });
    }

    _migrate_10() {
        this.model.annotations = {
            items: this.model.items
                .map((milestoneModel, idx) => ({
                    ...this.getDefaultAnnotationModel(),
                    nodeType: this.model.showContent ? NodeType.CONTENT_AND_TEXT : NodeType.TEXT,
                    content_type: milestoneModel.content_type,
                    content_value: milestoneModel.content_value,
                    hasBeenDragged: !!milestoneModel.hasBeenDragged,
                    body: milestoneModel.body,
                    title: milestoneModel.title,
                    x: milestoneModel.x ?? idx / this.model.items.length,
                    y: milestoneModel.y
                }))
                .sort((a, b) => a.x - b.x)
                .map((milestoneModel, idx) => ({
                    ...milestoneModel,
                    y: _.defaultTo(milestoneModel.y, idx % 2 === 0 ? 0.25 : 0.75)
                })),
            connections: {
                items: []
            }
        };

        delete this.model.items;
    }

    getAnimations() {
        const animations = [];

        if (this.elements.spans) {
            Object.values(this.elements.spans.itemElements)
                .forEach(element => {
                    animations.push(...element.getAnimations());
                });
        }

        animations.push(...this.annotations.getAnimations());

        return animations;
    }

    _exportToSharedModel() {
        const startMarker = this.startMarker ? this.startMarker._exportToSharedModel().textContent[0] : null;
        const endMarker = this.endMarker ? this.endMarker._exportToSharedModel().textContent[0] : null;

        const listContent = Object.values(this.annotations.elements).filter(el => el.id !== "connectors").map(el => {
            const { assets, textContent } = el._exportToSharedModel();
            return { asset: assets[0], text: textContent[0] };
        });

        const assets = Object.values(this.annotations.elements).filter(el => el.id !== "connectors").reduce((assets, el) => ([
            ...assets, ...el._exportToSharedModel().assets
        ]), []);

        const textContent = Object.values(this.annotations.elements).filter(el => el.id !== "connectors").reduce((textContent, el) => ([
            ...textContent, ...el._exportToSharedModel().textContent
        ]), []);

        return { listContent, assets, textContent, startMarker, endMarker };
    }

    _importFromSharedModel(model) {
        const listContent = detectListContent(model);
        if (!listContent?.length) return;

        const { startMarker, endMarker } = model;

        const items = listContent.map(({ asset, text }) => ({
            ...(asset ? {
                nodeType: NodeType.CONTENT_AND_TEXT,
                content_type: asset.type,
                content_value: asset.value,
                assetName: asset.name,
                assetProps: asset.props,
                ...(asset.configProps ?? {})
            } : {}),
            ...(text ? {
                ...(asset ? {} : {
                    nodeType: NodeType.TEXT,
                }),
                text: {
                    blocks: [
                        {
                            html: text.mainText.text,
                            textStyle: TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...text.secondaryTexts.map(secondaryText => ({
                            html: secondaryText.text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        })),
                    ]
                },
            } : {}),
        }));

        return {
            annotations: { items },
            ...(startMarker ? {
                showStartMarker: true,
                startMarker: {
                    start_marker: {
                        blocks: [
                            {
                                html: startMarker.mainText.text,
                                textStyle: startMarker.mainText || TextStyleType.TITLE,
                                type: AuthoringBlockType.TEXT,
                            },
                            ...startMarker.secondaryTexts.map(secondaryText => ({
                                html: secondaryText.text,
                                textStyle: TextStyleType.BODY,
                                type: AuthoringBlockType.TEXT,
                            })),
                        ]
                    }
                }
            } : {}),
            ...(endMarker ? {
                showEndMarker: true,
                endMarker: {
                    end_marker: {
                        text: endMarker.mainText.text,
                        blocks: [
                            {
                                html: endMarker.mainText.text,
                                textStyle: TextStyleType.TITLE,
                                type: AuthoringBlockType.TEXT,
                            },
                            ...endMarker.secondaryTexts.map(secondaryText => ({
                                html: secondaryText.text,
                                textStyle: TextStyleType.BODY,
                                type: AuthoringBlockType.TEXT,
                            })),
                        ]
                    }
                }
            } : {}),
            postProcessingFunction: async canvas => {
                const timelineElement = canvas.getPrimaryElement();
                await timelineElement.distributeMilestones();
            }
        };
    }
}

class TimelineLine extends SVGPolylineElement {
    getAnchorPointType() {
        return geom.AnchorType.FREE;
    }

    getAnchorPoint(connector, anchor, connectorPoint, connectorType) {
        return new geom.Point(connectorPoint.x, this.calculatedProps.bounds.centerV);
    }
}

class TimelineSpans extends CollectionElement {
    get gap() {
        return this.parentElement.styles.hGap;
    }

    getAnchorPoint(connector, anchor, connectorPoint, connectorType) {
        const spanHeight = Math.max(...this.itemElements.map(element => element.bounds.height)) / 2;
        const verticalOffset = connectorPoint.y < this.calculatedProps.bounds.centerV ? spanHeight : -spanHeight;
        return new geom.Point(connectorPoint.x, this.calculatedProps.bounds.centerV - verticalOffset);
    }

    get spanStyle() {
        // We should not use the fallback, it is here just in case
        return this.options.spanStyle ?? "chevron";
    }

    get collectionPropertyName() {
        return "spans";
    }

    get maxSpanCount() {
        return this.options.maxSpanCount;
    }

    get spanCount() {
        return this.options.spanCount;
    }

    get minItemCount() {
        return 0;
    }

    getChildOptions() {
        return {
            showStartMarker: this.options.showStartMarker,
            showEndMarker: this.options.showEndMarker,
            spanStyle: this.spanStyle
        };
    }

    getChildItemType() {
        return TimelineSpanItemContainer;
    }

    get childStyles() {
        return this.styles.TimelineSpanItemContainer;
    }

    _calcProps(props) {
        const { size } = props;
        this.availableWidth = size.width - (this.itemElements.length - 1) * this.gap;

        this.totalSpansWidth = _.sumBy(this.itemElements, item => item.model.width);

        let offsetX = 0;
        let maxHeight = 0;
        this.itemElements.forEach(item => {
            let itemWidth = this.getSpanWidth(item.model.width);
            let itemProps = item.calcProps(new geom.Size(itemWidth, size.height));
            maxHeight = Math.max(maxHeight, itemProps.size.height);
        });

        const offsetY = size.height / 2 - maxHeight / 2;
        for (const item of this.itemElements) {
            item.calculatedProps.bounds = new geom.Rect(offsetX, offsetY, item.calculatedProps.size);
            offsetX += item.calculatedProps.bounds.width + this.gap;
        }

        return { size };
    }

    getSpanWidth(gridWidth) {
        return gridWidth / this.totalSpansWidth * this.availableWidth;
    }
}

export class TimelineSpanItemContainer extends CollectionItemElement {
    static get schema() {
        return {
            width: 1
        };
    }

    get selectionBounds() {
        return new geom.Rect(this.shapeContainer.bounds.left,
            this.shapeContainer.bounds.top,
            this.shapeContainer.bounds.width,
            this.shapeContainer.bounds.height)
            .offset(this.canvasBounds.left, this.canvasBounds.top);
    }

    get chevronOffset() {
        return this.styles.chevronOffset ?? 0;
    }

    get spanStyle() {
        return this.options.spanStyle;
    }

    get height() {
        return this.styles.sliceHeight;
    }

    get selectionPadding() {
        return { left: 0, right: 0, top: 0, bottom: 20 };
    }

    get decorationStyle() {
        return this.model.decorationStyle || this.canvas.getTheme().get("styleElementStyle");
    }

    get allowDecorationStyles() {
        return true;
    }

    _build() {
        switch (this.spanStyle) {
            case "chevron": {
                this.shapeContainer = this.addElement("shapeContainer", () => TimelineSpanChevronItem, {
                    chevronOffset: this.chevronOffset,
                    showStartMarker: this.options.showStartMarker,
                    showEndMarker: this.options.showEndMarker
                });
                break;
            }
            case "slice":
            default: {
                this.shapeContainer = this.addElement("shapeContainer", () => TimelineSpanSliceItem);
                break;
            }
        }
    }

    _calcProps(props) {
        const { size } = props;

        const shapeWidth = (this.isAnimating ? size.width * this.animationState.value : size.width);

        const shapeProps = this.shapeContainer.calcProps(new geom.Size(shapeWidth, this.height));
        shapeProps.bounds = new geom.Rect(0, 0, shapeProps.size);
        const calculatedSize = new geom.Size(size.width, shapeProps.size.height);

        return { size: calculatedSize };
    }

    get animationElementName() {
        return `Slice #${this.itemIndex + 1}`;
    }

    getAnimations() {
        return [{
            name: "Grow in",
            element: this,
            prepare: () => {
                this.shapeContainer.label.animationState.fadeInProgress = 0;
                this.animationState.value = 0;
                this.parentElement.animationTextScale = this.parentElement.minTextScale;
            },
            onBeforeAnimationFrame: progress => {
                this.shapeContainer.label.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.animationState.value = progress;
                return this;
            }
        }];
    }
}

class TimelineMarker extends TextElement {
    get allowStyling() {
        return true;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [];
    }

    _loadStyles(styles) {
        if (!this.options.showTextDecoration) {
            styles.decoration = null;
        }
    }
}

export const elements = {
    Timeline
};
